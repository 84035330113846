import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Col, Container, Row, Button} from "react-bootstrap";

const AboutUs = () => {
	const data = useStaticQuery(graphql`
    query {
      melissa: file(relativePath: { eq: "Melissa-removebg-preview.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mirna: file(relativePath: { eq: "Mirna-nobg.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kary: file(relativePath: { eq: "Kary-nobg.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      anton: file(relativePath: { eq: "Anton-nobg.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
	return (
		<Layout>
			<SEO title="About us - Baushe" />
			<div className="bg-light">
				<Container className="py-5">
					<Row>
						<Col>
							<h1 className="text-center">About Baushe</h1>
							<p>Menopause is a natural but sometimes confusing phase. This is why we are building Baushe:. A platform that helps you to live through menopause in a positive way and on your terms.</p>
							<p>Baushe will take you by the hand, help you recognize your symptoms and provide techniques and resources to help ease the discomfort. At the same time, you’ll have the opportunity to meet with fellow women, at a similar stage in their lives, to exchange knowledge, support each other and grow together.</p>
							<p>Our brand was inspired by Bachué  the Colombian  goddess, often represented by a mature, wise woman. She has a fun, free-spirited personality and doesn't take herself too seriously. She  reminds us of the millions of goddesses out there going through the menopausal transition every year and who want to live their best life through it.</p>
							<p>Our platform  aims at being that fellow goddess that helps you go through this new (often confusing) stage so you can live it positively.</p>
						</Col>
					</Row>
					<Row className="mt-5">
						<Col>
							<h1 className="text-center mb-5">Our team</h1>
							<Row className="text-center justify-content-around">
								<Col xs="6" md="3">
									<div className="d-flex justify-content-center">
										<Img className="rounded-circle w-50 bg-white shadow" fluid={data?.melissa?.childImageSharp?.fluid}/>
									</div>
									<h3 className="mt-3">Melissa Romero</h3>
									<p className="font-weight-bold text-secondary">Co-founder & CEO</p>
									<p className="text-muted">
										Ex- P&G 10 yrs in consumer goods <br/>
										D&I Advocate: Founder Lean In NL
									</p>
								</Col>
								<Col xs="6" md="3">
									<div className="d-flex justify-content-center">
										<Img className="rounded-circle w-50 bg-white shadow" fluid={data?.mirna?.childImageSharp?.fluid}/>
									</div>
									<h3 className="mt-3">Mirna Sevilla</h3>
									<p className="font-weight-bold text-secondary">Head of Operations</p>
									<p className="text-muted">
										Sr. Business analyst <br/>
										8 yrs ex- Oracle
									</p>
								</Col>
								<Col xs="6" md="3">
									<div className="d-flex justify-content-center">
										<Img className="rounded-circle w-50 bg-white shadow" fluid={data?.anton?.childImageSharp?.fluid}/>
									</div>
									<h3 className="mt-3">Anton Tuyakhov</h3>
									<p className="font-weight-bold text-secondary">Co-founder & CTO</p>
									<p className="text-muted">
										10+ yrs in software engineering <br/>
										Medical Degree
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
					<div className="text-center mt-5">
						<Link to="/">Go back to the homepage</Link>
					</div>
				</Container>
			</div>
		</Layout>
	);
}

export default AboutUs
